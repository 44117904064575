<template>
  <!-- Lists all document types -->
  <div class="card-layout-table small-card-layout">
    <div class="header">
      <h2>Document Types</h2>
      <base-button
        v-if="isDocTypDisciplineManager"
        @click="toggleCreateDialog"
        icon="pi pi-plus"
        label="New Document
        type"
      ></base-button>
    </div>
    <document-types-list @edit-item="editItem"></document-types-list>
  </div>

  <!-- Dialog component for adding a new document type record -->
  <base-dialog title="Create document type" :open="showCreateDialog">
    <template #actions>
      <a @click="toggleCreateDialog">X</a>
    </template>
    <template #default>
      <create-document-type
        @task-completed="toggleCreateDialog"
      ></create-document-type>
    </template>
  </base-dialog>

  <!-- Dialog component for editing a document type record -->
  <base-dialog title="Edit document type" :open="showEditDialog">
    <template #actions>
      <a @click="toggleEditDialog">X</a>
    </template>
    <template #default>
      <edit-document-type
        @task-completed="toggleEditDialog"
        :document-type-id="editDocumentTypeId"
      ></edit-document-type>
    </template>
  </base-dialog>
</template>

<script>
import { ref, defineAsyncComponent } from "vue";

const CreateDocumentType = defineAsyncComponent(() =>
  import("../../components/documenttypes/CreateDocumentTypes.vue")
);
const EditDocumentType = defineAsyncComponent(() =>
  import("../../components/documenttypes/EditDocumentType.vue")
);

import DocumentTypesList from "../../components/documenttypes/DocumentTypesList.vue";

import useRoles from "../../hooks/roles";

export default {
  components: {
    DocumentTypesList,
    CreateDocumentType,
    EditDocumentType,
  },
  setup() {
    const showCreateDialog = ref(false);
    const showEditDialog = ref(false);
    const editDocumentTypeId = ref(null);

    /**
     * Toggles the add document type dialog by changing the showCreateDialog flag
     * true: Shows the dialog
     * false: Hides the dialog
     */
    function toggleCreateDialog() {
      showCreateDialog.value = !showCreateDialog.value;
    }

    /**
     * Toggles the edit document type dialog by changing the showEditDialog flag
     * true: Shows the dialog
     * false: Hides the dialog
     */
    function toggleEditDialog() {
      showEditDialog.value = !showEditDialog.value;
    }

    /**
     * Functions is called if component DocumentTypesList emits the event edit-item
     * The document type Id is set into the variable editDocumentTypeId
     * showEditDialog is set to true
     */
    function editItem(docTypeId) {
      editDocumentTypeId.value = docTypeId;
      showEditDialog.value = true;
    }

    const { isDocTypDisciplineManager } = useRoles();

    return {
      showCreateDialog,
      showEditDialog,
      toggleCreateDialog,
      toggleEditDialog,
      editDocumentTypeId,
      editItem,
      isDocTypDisciplineManager,
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
